var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 512 512" },
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        version: "1.1",
        width: "512",
        height: "512",
        x: "0",
        y: "0",
        viewBox: "0 0 512 512",
        "xml:space": "preserve",
      },
    },
    [
      _c("g", [
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }, [
          _c("g", [
            _c("path", {
              attrs: {
                d: "M414.004,303.627c-32.482,0-58.907,26.426-58.907,58.908c0,32.476,26.426,58.902,58.907,58.902    c32.487,0,58.908-26.426,58.908-58.902C472.912,330.053,446.486,303.627,414.004,303.627z M414.004,388.399    c-14.27,0-25.875-11.605-25.875-25.87c0-14.27,11.605-25.875,25.875-25.875s25.875,11.611,25.875,25.875    C439.88,376.793,428.274,388.399,414.004,388.399z",
                fill: "#ffffff",
                "data-original": "#000000",
              },
            }),
          ]),
        ]),
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }, [
          _c("g", [
            _c("path", {
              attrs: {
                d: "M329.221,90.563H16.516C7.394,90.563,0,97.957,0,107.08v254.348c0,9.122,7.394,16.516,16.516,16.516h51.751v-33.032    H33.032V123.596h279.673v237.832h33.032V107.08C345.738,97.957,338.344,90.563,329.221,90.563z",
                fill: "#ffffff",
                "data-original": "#000000",
              },
            }),
          ]),
        ]),
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }, [
          _c("g", [
            _c("rect", {
              attrs: {
                x: "149.196",
                y: "344.912",
                width: "222.417",
                height: "33.032",
                fill: "#ffffff",
                "data-original": "#000000",
              },
            }),
          ]),
        ]),
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }, [
          _c("g", [
            _c("path", {
              attrs: {
                d: "M108.456,303.627c-32.482,0-58.908,26.426-58.908,58.908c0,32.476,26.426,58.902,58.908,58.902    c32.482,0,58.908-26.426,58.908-58.902C167.363,330.053,140.938,303.627,108.456,303.627z M108.456,388.399    c-14.27,0-25.875-11.605-25.875-25.87c0-14.27,11.605-25.875,25.875-25.875c14.27,0,25.875,11.611,25.875,25.875    C134.331,376.793,122.72,388.399,108.456,388.399z",
                fill: "#ffffff",
                "data-original": "#000000",
              },
            }),
          ]),
        ]),
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }, [
          _c("g", [
            _c("path", {
              attrs: {
                d: "M495.484,227.647H329.221v33.032h149.746v84.232h-23.673v33.032h40.189c9.122,0,16.516-7.388,16.516-16.516V244.163    C512,235.041,504.606,227.647,495.484,227.647z",
                fill: "#ffffff",
                "data-original": "#000000",
              },
            }),
          ]),
        ]),
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }, [
          _c("g", [
            _c("path", {
              attrs: {
                d: "M445.545,159.127c-2.901-5.274-8.451-8.555-14.474-8.555H329.221v33.032h92.083l37.684,68.52l28.947-15.922    L445.545,159.127z",
                fill: "#ffffff",
                "data-original": "#000000",
              },
            }),
          ]),
        ]),
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }),
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }),
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }),
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }),
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }),
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }),
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }),
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }),
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }),
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }),
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }),
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }),
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }),
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }),
        _c("g", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }